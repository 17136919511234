import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import "../sass/main.scss";
import gaming_work_image from "../images/Gaming Related Work Banner.png";
import IndexPage from "./index";


const GamingWorkPage = () => {
    const base_url = "https://cms.krestonshirley.com"

    const orgs = useStaticQuery(graphql`
  {
    allNodeOrganizationsWorkedFor(sort: {fields: field_years_worked___value, order: DESC}) {
      edges {
        node {
          body {
            summary
          }
          title
          path {
            alias
          }
          relationships {
            field_org_logo {
              id
              relationships {
                field_media_image_3 {
                  uri {
                    url
                  }
                }
              }
            }
          }
          field_years_worked {
            value(formatString: "YYYY")
            end_value(formatString: "YYYY")
          }
        }
      }
    }
  }
`)

    let {edges} = orgs.allNodeOrganizationsWorkedFor

    return (
        <Layout>
            <SEO
                title="Gaming Related Work"
                pageBanner={gaming_work_image}
                description="Learn about organizations that I’ve started and worked with in the gaming space. My roles at these organizations vary in responsibilities."
            />
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 text-center">
                        <h1>Gaming Related Work</h1>
                        <p>Below are organizations that I’ve started and worked with in the gaming space. My roles at these organizations vary in responsibilities. These responsibilities include leadership, business operations, business development, event planning and more. </p>
                    </div>
                </div>
                {
                    edges.map( node => {
                        let {title} = node.node
                        let {path: {alias} = {alias: '#'}} = node.node
                        let {
                            relationships: {
                                field_org_logo: {
                                    relationships: {
                                        field_media_image_3: {
                                            uri: {
                                                url
                                            },
                                        },
                                    }
                                },
                            }
                        } = node.node
                        let {body: {summary}} = node.node;

                        return (
                            <div className="row gaming-work-row">
                                <div className="col-sm-12 col-md-6 gaming-work-img d-flex justify-content-center">
                                    <Link to={alias}><img src={base_url + url} alt=""/></Link>
                                    {/*<img src="https://via.placeholder.com/300" alt=""/>*/}
                                </div>
                                <div className="col-sm-12 col-md-6 gaming-work-text d-flex flex-column justify-content-center">
                                    <h2 className="text-center">{title}</h2>
                                    <p>{summary}</p>
                                    <Link className="btn btn-outline-secondary kes-button align-self-center" to={alias}>Learn More</Link>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </Layout>
    )
}

// export const pageQuery = graphql`
//   query IndexQuery {
//     allMarkdownRemark {
//     edges {
//       node {
//         html
//       }
//     }
//   }}
// `

export default GamingWorkPage